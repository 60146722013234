const createImageUrl = (url, height = 100, width = 100, fit = 'contain') => {
    let edits = {
        bucket: process.env.REACT_APP_AWS_IMAGE_BUCKET,
        key: url,
        edits: {
            resize: {
                width: width,
                height: height,
                fit: fit,
                background: "transparent"
            }
        }
    };
    let qs = btoa(JSON.stringify(edits));
    let base = process.env.REACT_APP_AWS_IMAGE_ENDPOINT;
    return `${base}/${qs}`;
}

export default createImageUrl;
