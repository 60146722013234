import {createSlice} from "@reduxjs/toolkit";
import {getToken} from "../actions/authActions";

const token = localStorage.getItem('token')
    ? localStorage.getItem('token')
    : null

const initialState = {
    loading: false,
    token, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: {
        [getToken.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getToken.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.token = payload.access_token;
            state.success = true
            localStorage.setItem('token', payload.access_token);
        },
        [getToken.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    },
})

export default authSlice.reducer
