import React from "react";

const SeriesExplainer = () => {
    return <div className={'h-screen flex flex-col'}>
        <div className="flex">
            <div className="w-1/3">
                <img src={"./assets/images/logo/GAMES_WHITE.svg"} alt={"Brawn Games 2023"}/>
            </div>
        </div>
        <div className={'text-white flex justify-center align-middle items-center h-full'}>
            <div className={'relative w-[102rem] h-[59rem]'}>
                <div id={"red"} style={{
                    borderRadius: "0.1rem",
                    background: "linear-gradient(44deg, rgba(19, 21, 28, 0.20) 0%, rgba(0, 0, 0, 0.00) 45.59%), #FD2B12",
                    width: "94rem",
                    height: "54rem",
                    left: 0,
                    top: 0,
                    marginTop: "5rem",
                    position: "absolute"
                }}>&nbsp;</div>
                <div id={"blue"} style={{
                    borderRadius: "0.1rem",
                    background: "linear-gradient(90deg, rgba(19, 21, 28, 0.12) 2.74%, rgba(255, 255, 255, 0.12) 79.23%), var(--Color-Background, #13151C)",
                    boxShadow: "-72px 67px 44px 0px rgba(19, 21, 28, 0.15), -72px 67px 49px 0px rgba(19, 21, 28, 0.35)",
                    width: "94rem",
                    height: "54rem",
                    marginLeft: "8rem",
                    top: 0,
                    left: 0,
                    position: "absolute",
                }}>
                    {/*<img src={'./assets/images/blurry_chevron.png'}*/}
                    {/*     style={{position: "absolute", objectFit: "cover", width: "100%", left: 0, top: 0, bottom: 0, right: 0, height: "100%"}} alt={''}/>*/}
                    <div className={'text-4xl leading-loose p-16'}>
                        <h1 className={'text-6xl text-white font-bold text-center mb-8 uppercase'}>How it works</h1>
                        <p className={'mb-8'}>There are 4 challenges, 2 AMRAPs and 2 Rounds challenges.</p>
                        <p className={'mb-8'}>You’ll be competing in teams of 4 in each challenge.</p>
                        <p className={'mb-8'}>AMRAP challenges are ranked by total reps per team.</p>
                        <p className={'mb-8'}>Rounds challenges are ranked by total rounds per team.</p>
                        <p className={'mb-8'}>The top 10 teams in each challenge earn points towards the Brawn Games title.</p>
                        <p className={'mb-8'}>10 points for first place down to 1 point for 10th.</p>
                        <p className={'font-bold'}>Let the games begin!</p>
                    </div>
                </div>
            </div>
        </div>
        <img src={"./assets/images/optimum-nutrition-logo-white.png"} alt="Brawn" className="absolute right-10 bottom-8 w-64"/>
    </div>
}

export default SeriesExplainer;
