import {motion} from "framer-motion";
import * as PropTypes from "prop-types";
import React from "react";
import createImageUrl from "../../utils/Image";

function ShinyUserAvatar(props) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    if (!props.name) {
        return <span
            className="rounded-full flex text-9xl justify-center items-center uppercase bg-gray-600 w-[250px] h-[250px]">
        ?</span>
    }

    let initials = [...props.name.matchAll(rgx)] || [];

    initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    if (!props.avatarUrl) {
        return <span
            className="rounded-full flex text-9xl justify-center items-center uppercase bg-gray-600 w-[250px] h-[250px]">
        {initials}</span>
    }
    return <div className="rounded-full object-cover w-[250px] h-[250px]">
        <img
            src={createImageUrl(props.avatarUrl, 250, 250, props.rounded ? "cover" : "contain")}
            className={" " + (props.rounded ? "rounded-full" : "")}
            alt="avatar"
        />
    </div>;
}

ShinyUserAvatar.propTypes = {
    avatarUrl: PropTypes.string,
    name: PropTypes.string,
    rounded: PropTypes.bool
};

const FirstPlaceBox = (props) => {
    return <>
        <div className={'flex justify-between mb-5'}>
            {props.avatarUrl &&
                <div>
                    <ShinyUserAvatar avatarUrl={props.avatarUrl} name={props.name} rounded={true}/>
                </div>
            }
            <div className={'text-8xl font-bold'}>
                <span className={'text-5xl'}>#</span>1
            </div>
        </div>
        <h3 className={'font-normal text-6xl mt-8'}>
            {props.result &&
                <>{props.result?.value.toLocaleString()} {props.text}</>
            }
        </h3>
        <div className={'flex items-center'}>
            <h2 className="text-white font-bold overflow-hidden w-[100%] text-6xl mt-8 uppercase ">
                {`${props.name}`.length > 10 ? (
                    <motion.div
                        animate={{x: ["0%", `${((props.name.length - 10) / 10) * -100}%`, "0%"]}}
                        transition={{
                            ease: "linear",
                            delay: 2,
                            duration: 10,
                            repeat: Infinity,
                            repeatDelay: 2,
                        }}
                        className="flex gap-1 whitespace-nowrap"
                    >
                        {props.name}
                    </motion.div>
                ) : (
                    <span className={'py-3'}>
                          {props.name}
                        </span>
                )}
                {!props.name &&
                    <span className={'font-bold uppercase'}>Attempts Pending</span>
                }
            </h2>
        </div>
    </>
}

const FirstPlace = (props) => {
    return <div className={'relative mt-16'}>
        <div id={"red"} style={{
            borderRadius: "0.1rem",
            background: "linear-gradient(44deg, rgba(19, 21, 28, 0.20) 0%, rgba(0, 0, 0, 0.00) 45.59%), #FD2B12",
            width: "85%",
            maxWidth: "45rem",
            minWidth: "35rem",
            aspectRatio: "1/1.05",
            left: 0,
            top: 0,
            marginTop: "7rem",
            position: "absolute"
        }}>&nbsp;</div>
        <div id={"blue"} style={{
            borderRadius: "0.1rem",
            background: "linear-gradient(90deg, rgba(19, 21, 28, 0.12) 2.74%, rgba(255, 255, 255, 0.12) 79.23%), var(--Color-Background, #13151C)",
            boxShadow: "-72px 67px 44px 0px rgba(19, 21, 28, 0.15), -72px 67px 49px 0px rgba(19, 21, 28, 0.35)",
            width: "85%",
            maxWidth: "45rem",
            minWidth: "35rem",
            aspectRatio: "1/1.05",
            marginLeft: "8rem",
            top: 0,
            left: 0,
            padding: "5rem",
            position: "absolute"
        }}>
            <FirstPlaceBox {...props}/>
        </div>
    </div>
}

FirstPlace.propTypes = {
    avatarUrl: PropTypes.string,
    name: PropTypes.string,
    result: PropTypes.any,
    text: PropTypes.string,
    rounded: PropTypes.bool
};

export default FirstPlace;
