import {motion} from "framer-motion";
import React from "react";

const TimerBar = () => {
    return <div className="w-full absolute" style={{bottom: 0, right: 0, left: 0}}>
        <motion.div
            animate={{width: ["0%", "100%"]}}
            className={"bg-red-600 h-2"}
            transition={{
                ease: "linear",
                duration: 20,
                repeat: Infinity
            }}>
        </motion.div>
    </div>
}

export default TimerBar;
