import React from "react";
import {Route, Routes} from "react-router-dom";
import "./App.css";
import Main from "./components/Main";

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Main/>}>
                <Route path=":seriesId" element={<Main/>}/>
            </Route>
        </Routes>
    );
};

export default App;
