import React, {useEffect} from "react";

// api
import MissingSeries from "./MissingSeries";
import Layout from "./Layout";
import Loading from "../utils/Loading";
import Screens from "./Screens";
import {useDispatch, useSelector} from "react-redux";
import {getToken} from "../actions/authActions";


const Main = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const seriesId = urlParams.get('series');
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    // dispatch(getToken());
    useEffect(() => {
        if (!auth.token && !auth.loading) {
            dispatch(getToken());
        }
    }, [auth]);
    if (!auth.token) {
        return <Layout>
            <Loading/>
        </Layout>
    }

    if (!seriesId) {
        return <Layout><MissingSeries/></Layout>;
    }
    return <Layout>
        <Screens seriesId={seriesId}/>
    </Layout>;
};

export default Main;
