import React from "react";
import TimerBar from "./TimerBar";

const Layout = (props) => {
    return <div className="p-5 min-h-screen" style={{background: "linear-gradient(90deg, #18131C 0%, #34394C 41.07%, #13151C 100%)"}}>
        {/*<img src="./assets/images/Condensed-logo.png" alt="" className="absolute left-0 top-0 w-full h-full p-5"/>*/}
        {/*<img src="./assets/images/logo.png" alt="Brawn" className="absolute right-10 bottom-5"/>*/}
        <TimerBar/>
        {props.children}
    </div>;
}

Layout.propTypes = {};

export default Layout;
