import React from "react";

const Welcome = () => {
    return (
        <>
            {/*<img src="./assets/images/logo/ministry-of-sound-logo-svg-vector.svg" alt="" className={'max-h-full max-w-full'}/>*/}
            <div className="flex justify-center px-10 items-center text-white flex-col h-full  min-h-screen mx-auto" style={{background: 'url("./assets/images/logo/ministry-of-sound-logo-svg-vector.svg") no-repeat center'}}>
                <div className="text-center">
                    <img src="./assets/images/logo/Brawn_games_2023.svg" alt="" className={'max-h-full max-w-full'}/>
                </div>
                <p className={'text-center text-white text-4xl flex-shrink py-12'}>In association with</p>
                <div className="text-center">
                    <img src="./assets/images/optimum-nutrition-logo-white.png" alt="" className={'max-h-full max-w-full'}/>
                </div>
            </div>
            {/*<img src="./assets/images/logo.png" alt="Brawn" className="absolute right-10 bottom-5"/>*/}
        </>
    );
};
Welcome.active = "download";

export default Welcome;
