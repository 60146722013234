import React from "react";

const MissingSeries = () => {
    return (
        <section className="flex justify-center gap-48 px-10 items-center h-4/5 text-white min-h-screen">
            The site must be opened with a Series ID provided in the URL, such as:
            <br/>
            {window.location.origin}?series=1234
        </section>
    );
};

export default MissingSeries;
