import React from "react";
import * as PropTypes from "prop-types";
import {useGetSeriesChallengesQuery} from "../store/apiSlice";

function HeaderItem(props) {
    let activeClasses = "border-[#FD2B12] text-white font-bold";
    let inactiveClasses = "border-zinc-500 font-semibold";
    let classes = props.active ? activeClasses : inactiveClasses;
    return <span className={`border-b px-8 ${classes} flex flex-col justify-center whitespace-pre-wrap text-center pb-4`} style={{borderBottomWidth: 4}}>{props.text}</span>;
}

HeaderItem.propTypes = {
    active: PropTypes.bool,
    text: PropTypes.string
};

function GroupLogo(props) {
    let edits = {
        bucket: process.env.REACT_APP_AWS_IMAGE_BUCKET,
        key: props.url,
        edits: {
            resize: {
                width: 310,
                height: 100,
                fit: "inside"
            }
        }
    };
    let qs = btoa(JSON.stringify(edits));
    let base = process.env.REACT_APP_AWS_IMAGE_ENDPOINT;
    let groupLogo = `${base}/${qs}`;
    return <div className={"h-[100px] w-[310px] relative"}>
        {props.url &&
            <img className={'max-h-full'} src={groupLogo} alt={props.alt}/>
        }
        {!props.url && props.name &&
            <h1 className={"text-5xl text-white"}>{props.name}</h1>
        }
    </div>;
}

GroupLogo.propTypes = {
    url: PropTypes.string,
    name: PropTypes.string
};
const Header = ({active}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const seriesId = urlParams.get('series');
    const {data: challenges, isLoading: challengesLoading} = useGetSeriesChallengesQuery(seriesId);
    if (challengesLoading) {
        return;
    }

    let items = [
        {key: "series_rankings", name: "Team Rankings"},
    ];
    challenges.data.forEach(item => items.push({key: item.id, name: item.name}));
    return (
        <div className="flex">
            <div className="w-1/3">
                <img src={"./assets/images/logo/GAMES_WHITE.svg"} alt={"Brawn Games 2023"}/>
                {/*<GroupLogo name={data?.data.name} url={data?.data.avatar_url}/>*/}
            </div>
            <div className="text-[#7A7A7A] flex text-3xl w-2/3 justify-end">
                <div className={'flex overflow-ellipsis whitespace-nowrap'}>
                    {items.map(({key, name}) => <HeaderItem active={active == key} text={name} key={key}/>)}
                </div>
            </div>
        </div>
    );
};

export default Header;
