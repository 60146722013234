import {useGetChallengeRankingsQuery,} from "../../store/apiSlice";
import Leaderboard from "./Leaderboard";
import * as PropTypes from "prop-types";
import Loading from "../../utils/Loading";

const ChallengeIndividualRankings = (props) => {
    const {data, isLoading} = useGetChallengeRankingsQuery({leaderboardId: props.leaderboardId, seriesId: props.seriesId});
    if (isLoading) {
        return <Loading/>;
    }
    let rows = data?.data.map(user => ({avatar_url: user.avatar_url, name: user.first_name + " " + user.last_name, result: user.result, id: user.id, rank: user.rank}));
    return (
        <Leaderboard
            data={{data: rows}}
            isLoading={isLoading}
            text={props.text}
            roundedAvatars={true}
            dataType={"user"}
            showPills={props.showPills}
        />
    );
};

ChallengeIndividualRankings.propsTypes = {
    leaderboardId: PropTypes.number,
    seriesId: PropTypes.number,
    text: PropTypes.string,
}
export default ChallengeIndividualRankings;
