import React from "react";
import Loading from "../../utils/Loading";
import {motion} from "framer-motion";
import * as PropTypes from "prop-types";
import createImageUrl from "../../utils/Image";
import {useGetSeriesChallengesQuery} from "../../store/apiSlice";
import FirstPlace from "./FirstPlace";

function LeaderboardPill(props) {
    let classes = props.active ? "bg-[#C2FAA5]" : "bg-[#504B68]";
    return <span className={`px-3 py-3 ${classes} rounded-lg font-bold`}>{props.title}</span>;
}

LeaderboardPill.propTypes = {
    active: PropTypes.bool,
    title: PropTypes.any
};

function LeaderboardPills(props) {
    const {data, isLoading} = useGetSeriesChallengesQuery(props.seriesId);
    if (isLoading) {
        return <></>;
    }
    let items = {};
    data.data.forEach(item => {
        items[item.id] = item.name;
    })
    return <div className="mt-5 flex gap-5 text-3xl font-semibold justify-items-end">
        {Object.getOwnPropertyNames(items).map((prop, key) => <LeaderboardPill active={props.active === prop} title={items[prop]} key={key}/>)}
    </div>;
}

LeaderboardPills.propTypes = {active: PropTypes.any};

function UserAvatar(props) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...props.name.matchAll(rgx)] || [];

    initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    if (props.avatarUrl) {
        let path = createImageUrl(props.avatarUrl, 64, 64, props.rounded ? "cover" : "contain");
        return <img src={path} alt="" className={"w-16 h-16 object-cover " + (props.rounded ? "rounded-full" : "")}/>;
    }
    return <span className="flex text-3xl justify-center items-center rounded-full uppercase bg-gray-600 w-16 h-16">
        {initials}</span>;
}

UserAvatar.propTypes = {
    avatarUrl: PropTypes.any,
    name: PropTypes.any,
    rounded: PropTypes.bool
};
const Leaderboard = ({data, isLoading, text, roundedAvatars, dataType, showPills}) => {
    if (isLoading) {
        return <>
            {/*<LeaderboardPills active={active}/>*/}
            <Loading/>
        </>
    }
    let rows = data?.data.slice(1).map(
        ({id, name, rank, avatar_url, result}, index) =>
            (
                <div key={index} className="flex justify-between border-b pb-5 text-3xl">
                    <div className="flex gap-5 items-center font-bold">
                        <div className={'w-12'}>{rank}</div>
                        <div className="flex justify-center items-center ">
                            <UserAvatar avatarUrl={avatar_url} name={name} rounded={true}/>
                        </div>
                        <div className="w-[50rem] overflow-hidden font-bold">
                            {`${name}`.length > 30 ? (
                                <motion.div
                                    animate={{x: ["0rem", `${((name.length - 30) / 30) * -100}%`, "0rem"]}}
                                    transition={{
                                        ease: "linear",
                                        delay: 2,
                                        duration: 7,
                                        // repeat: Infinity,
                                        // repeatDelay: 1,
                                    }}
                                    className="flex gap-1 whitespace-nowrap"
                                >
                                    {name}
                                </motion.div>
                            ) : (
                                <span className="whitespace-nowrap">{name}</span>
                            )}
                        </div>
                    </div>
                    <div className="text-white flex items-center font-bold">
                        {result.value.toLocaleString()}
                    </div>
                </div>
            )
    );
    let active = 'text-red-600';
    return (
        <>
            <div className="flex gap-28 text-white" style={{marginLeft: "2%"}}>
                <div className={'w-2/5'}>
                    <FirstPlace avatarUrl={data?.data[0]?.avatar_url} name={data?.data[0]?.name} result={data?.data[0]?.result}
                                text={text} rounded={roundedAvatars}/>
                </div>
                <div className={'w-3/5'}>
                    <div className={'px-20'}>
                        {showPills &&
                            <div className={'flex w-1/2 mx-auto mt-10'}>
                                <p className={'flex-1 p-2 text-center text-5xl font-bold ' + (dataType === 'team' ? active : "")}>Teams</p>
                                <p className={'flex-1 p-2 text-center text-5xl font-bold ' + (dataType === 'user' ? active : "")}>Members</p>
                            </div>
                        }
                        <div className={'overflow-hidden mt-8'} style={{height: "40rem"}}>
                            {rows.length > 6 &&
                                <motion.div
                                    animate={{y: ["0rem", (((rows.length - 6) / 6) * -40) + "rem"]}}
                                    transition={{
                                        ease: "linear",
                                        delay: 4,
                                        duration: 12,
                                        // repeat: Infinity,
                                        // repeatDelay: 1,
                                    }}
                                    className="flex flex-col gap-6 w-full">
                                    {rows}
                                </motion.div>
                            }
                            {rows.length <= 8 && <div className={"flex flex-col gap-6 w-full"}>{rows}</div>}
                            {rows.length === 0 && <p className={'text-center text-white text-5xl font-bold mt-40'}>Attempts Pending</p>}
                        </div>
                    </div>
                </div>
            </div>
            <img src="./assets/images/optimum-nutrition-logo-white.png" alt="Brawn" className="absolute right-10 bottom-8 w-64"/>
        </>
    );
};

Leaderboard.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    text: PropTypes.string,
    roundedAvatars: PropTypes.bool,
    dataType: PropTypes.string,
    showPills: PropTypes.bool
}
export default Leaderboard;
