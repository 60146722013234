import {useGetSeriesRankingsQuery,} from "../../store/apiSlice";
import Leaderboard from "./Leaderboard";

const SeriesRankings = (props) => {
    const {data, isLoading} = useGetSeriesRankingsQuery(props.seriesId);
    let rows = data?.data.map(team => ({avatar_url: team.avatar_url, name: team.name, result: team.result, id: team.id, rank: team.rank}));
    return (
        <Leaderboard
            data={{data: rows}}
            isLoading={isLoading}
            text={"Points"}
            active={null}
            seriesId={props.seriesId}
            dataType={"team"}
        />
    );
};
export default SeriesRankings;
