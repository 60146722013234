import {useGetChallengeTeamRankingsQuery,} from "../../store/apiSlice";
import Leaderboard from "./Leaderboard";
import * as PropTypes from "prop-types";
import Loading from "../../utils/Loading";

const ChallengeTeamRankings = (props) => {
    const {data, isLoading} = useGetChallengeTeamRankingsQuery({seriesId: props.seriesId, challengeId: props.challengeId});
    if (isLoading) {
        return <Loading/>;
    }
    let rows = data?.data.map(team => ({avatar_url: team.avatar_url, name: team.name, result: team.result, id: team.id, rank: team.rank}));
    return (
        <Leaderboard
            data={{data: rows}}
            isLoading={isLoading}
            text={props.text}
            active={"team_rankings"}
            title={props.challengeName}
            rounded={false}
            dataType={"team"}
            showPills={props.showPills}
        />
    );
};

ChallengeTeamRankings.propsTypes = {
    challengeId: PropTypes.number,
    seriesId: PropTypes.number,
    text: PropTypes.string,
    challengeName: PropTypes.string
}
export default ChallengeTeamRankings;
