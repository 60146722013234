import {useGetSeriesChallengesQuery} from "../store/apiSlice";
import React, {useEffect, useState} from "react";
import SeriesRankings from "./leaderBoards/SeriesRankings";
import Header from "../utils/Header";
import {useDispatch} from "react-redux";
import ChallengeTeamRankings from "./leaderBoards/ChallengeTeamRankings";
import ChallengeIndividualRankings from "./leaderBoards/ChallengeIndividualRankings";
import Welcome from "./Welcome";
import SeriesExplainer from "./community/SeriesExplainer";

function getMetric(value) {
    switch (value) {
        case "reps":
            return "Reps";
        case "intensity_points":
            return "Intensity Points";
        case "points":
            return "Points";
        case "rounds":
            return "Rounds"
        default:
            return value;
    }
}

const Screens = (props) => {
    const [currentComponent, setCurrentComponent] = useState(<Welcome/>);
    const {data, isLoading, refetch} = useGetSeriesChallengesQuery(props.seriesId);
    const dispatch = useDispatch();
    useEffect(() => {

        let components = [
            <SeriesExplainer/>,
            <>
                <div className="p-5">
                    <Header active={"series_rankings"}/>
                </div>
                <SeriesRankings seriesId={props.seriesId} showPills={false}/>
            </>
        ];

        if (!isLoading && data) {
            data.data.forEach((item) => {
                let leaderboard = item.leaderboards[0];
                components.push(<>
                    <div className="p-5">
                        <Header active={item.id}/>
                    </div>
                    <ChallengeTeamRankings seriesId={props.seriesId} challengeId={item.id} text={getMetric(leaderboard.default_metric)} challengeName={item.name} showPills={true}/>
                </>)
                components.push(<>
                    <div className="p-5">
                        <Header active={item.id}/>
                    </div>
                    <ChallengeIndividualRankings seriesId={props.seriesId} leaderboardId={leaderboard.id} text={getMetric(leaderboard.default_metric)} showPills={true}/>
                </>)
            });
            components.push(<Welcome/>);

            // CHANGE COMPONENT AFTER EACH 12 SECONDS
            let index = 0;

            const interval = setInterval(() => {
                if (index === (components.length - 1)) {
                    refetch();
                }
                setCurrentComponent(components[index]);
                index = (index + 1) % components.length;
            }, 20000);
            return () => clearInterval(interval);
        }
    }, [isLoading, data, props.seriesId, dispatch, refetch]);

    return <>
        {currentComponent}
    </>;
}


export default Screens;
