import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URI,
        prepareHeaders: (headers, {getState}) => {
            headers.set('Content', 'application/json');
            headers.set('Accept', 'application/json');
            const token = getState().auth.token
            if (token) {
                // include token in req header
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
            return headers;
        }
    }),
    reducerPath: "GymApi",
    tagTypes: [
        "token",
        'group',
        'series'
    ],
    endpoints: (builder) => ({
        getGroup: builder.query({
            query: (id) => ({
                url: `/api/group/${id}`,
                method: "GET"
            }),
            providesTags: ["group"],
        }),
        getSeries: builder.query({
            query: (id) => ({
                url: `/api/challenge-series/${id}`,
                method: "GET"
            }),
            providesTags: ["series"],
        }),
        getSeriesChallenges: builder.query({
            query: (id) => ({
                url: `/api/challenge-series/${id}/challenges`,
                method: "GET"
            }),
            providesTags: ['series', 'challenge'],
        }),
        getSeriesRankings: builder.query({
            query: (id) => ({
                url: `/api/challenge-series/${id}/rank`,
                method: "GET"
            }),
            providesTags: ['series', 'challenge'],
        }),
        getChallengeTeamRankings: builder.query({
            query: ({seriesId, challengeId}) => ({
                url: `/api/challenge-series/${seriesId}/group-challenge/${challengeId}/rank`,
                method: "GET"
            }),
            providesTags: ['series', 'challenge'],
        }),
        getChallengeRankings: builder.query({
            query: ({leaderboardId, seriesId}) => ({
                url: `/api/group-challenge-leaderboard/${leaderboardId}/rank?challenge_series_id=${seriesId}`,
                method: 'GET'
            }),
            providesTags: ['series', 'challenge']
        })
    }),
});

export const {
    useGetGroupQuery,
    useGetSeriesQuery,
    useGetSeriesChallengesQuery,
    useGetSeriesRankingsQuery,
    useGetChallengeRankingsQuery,
    useGetChallengeTeamRankingsQuery
} = apiSlice;
