// authActions.js
import axios from 'axios'
import {createAsyncThunk} from '@reduxjs/toolkit'

const backendURL = process.env.REACT_APP_API_URI;

export const getToken = createAsyncThunk(
    'auth/token',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            }
            let response = await axios.post(
                `${backendURL}/oauth/token`,
                {
                    client_id: process.env.REACT_APP_API_CLIENT_ID,
                    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
                    grant_type: "client_credentials",
                    scope: "read-users read-workouts read-groups read-challenge-series",
                },
                config
            );
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            // if (error.response && error.response.data.message) {
            //     return rejectWithValue(error.response.data.message)
            // } else {
            //     return rejectWithValue(error.message)
            // }
        }
    }
)
